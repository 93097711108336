import React, {useEffect, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import ReactHtmlParser from "react-html-parser";
import './produits.css'
import MediaPicto from "../query/PictoQuery";
import "@fontsource/open-sans"
import {
    Table,
    Tbody,
    Tr,
    Td,
} from '@chakra-ui/react'
import {Center} from '@chakra-ui/react'
import {
    Tag,
} from '@chakra-ui/react'
import Link from "gatsby-link";


const ProduitsFamille = (props) => {


    const produitsDatas = useStaticQuery(graphql`
        query {
          allWpCategory {
            nodes {
              id
              name
            }
          }
          allWpTag {
            edges {
              node {
                id
                name
                posts {
                  nodes {
                    id
                    title
                    categories {
                      nodes {
                        id
                        name
                      }
                    }
                    features {
                      fieldGroupName
                      produitCode
                      produitDescription
                      produitRefSetem
                      produitRefUsine
                      produitTelechargement
                    }
                  }
                }
              }
            }
          }
          allWpPost {
            nodes {
              id
              title
              tags {
                nodes {
                  name
                  id
                }
              }
              categories {
                nodes {
                  id
                  name
                }
              }
              features {
                fieldGroupName
                produitCode
                produitDescription
                produitRefSetem
                produitRefUsine
                produitTelechargement
              }
            }
          }
          allSetemProduct{
            edges{
              node{
                key,
                hit{
                  _source{
                    usine
                    reference
                    results
                    image_url
                    code
                    brand
                  }
                }
              }
            }
          }
        }
    `)
    const marques = produitsDatas.allWpCategory
    const familles = produitsDatas.allWpTag
    const all_products = produitsDatas.allWpPost
    const setemProducts = produitsDatas.allSetemProduct
    let obj = {}

    let marque = {};
    let famille_princ = {};
    let marque_logo_url = "";
    marques.nodes.map((item, index) => {
        if (item.id === props.id_marque) {
            marque = item;
            marque_logo_url = "https://media-setem.s3.eu-central-1.amazonaws.com/marques/" + marque.name.toLowerCase() + "/marque_type_" + marque.name.toLowerCase() + ".png"

        }
    })

    familles.edges.map((item, index) => {
        if (item.node.id === props.id_famille) {
            famille_princ = item.node;
            //console.log(famille_princ)
        }
    })


    let products = [];


    familles.edges.map((famille, index) => {
        if (famille.node.id === props.id_famille) {
            famille.node.posts.nodes.map((produit, idx) => {
                //console.log(produit)
                if (produit.categories.nodes[0].id === props.id_marque) {
                    products.push(produit)
                }
            })
        }
    })


    function getAllProducts(param) {

        if (param.id_famille === 0) {
            let tab = []
            all_products.nodes.map((prod, index) => {

                if (prod.categories.nodes[0].id === param.id_marque) {
                    tab.push(prod)
                }
            })
            return tab
        }
        return []

    }

    function getProductsCategorise(param) {
        let tab = []
        all_products.nodes.map((produit, index) => {

            if (produit.categories.nodes[0].id === param.id_marque) {

                if (produit.tags.nodes.length > 0) {
                    tab.push(produit)
                }
            }

        })

        return tab
    }

    function deleteDoublonsBetweenTables(tab1, tab2) {
        let diff = [] = tab1.filter(x => !tab2.includes(x))
            .concat(tab2.filter(x => !tab1.includes(x)))

        return diff
    }

    function makeDecisionToShowProducts(tab, param) {
        if (param.id_famille === 0) {
            return deleteDoublonsBetweenTables(getAllProducts(param), getProductsCategorise(param))
        }

        return tab;
    }


    const url_to_fetch = "https://5306h0oq6b.execute-api.eu-central-1.amazonaws.com/opensearch-api-test?q="
    let refProduitsSearch = []


    const [starsCount, setStarsCount] = useState(0)

    const [loading, setLoading] = useState(false)

    //GetProductInfos("2443000008")

    function GetProductInfosFromGraphQL(ref){
        let infos = {};
        infos.images = []
        infos.referentiels = []

        setemProducts.edges.map((prod,index) => {
            if(prod.node.hit._source.reference === ref){
                if(prod.node.hit._source.usine){
                    infos.details = prod.node.hit._source
                }
                else {
                    infos.images.push(prod.node.hit._source.image_url)
                    prod.node.hit._source.results.map((res,indx) => {
                        if (!infos.referentiels.includes(res)) {
                            infos.referentiels.push(res);
                        }
                    })
                }
            }
        })

        infos.images = changeImagesOrder(infos.images)

        return infos
    }

    function GetProductInfos(ref) {
        const [product_infos, setProductInfos] = useState({})
        const [imagesLoaded, setImages] = useState([])
        let infos = {};
        infos.images = []
        infos.referentiels = []
        //let images = [];
        useEffect(() => {
            fetch(url_to_fetch + ref)
                .then(function (data) {
                    return data.json().then(function (result) {
                        //setProductInfos(result.hits.hits)
                        result.hits.hits.map((hit, index) => {
                            if (hit._source.reference === ref) {

                                if (hit._source.usine) {
                                    //tableau des images produit
                                    infos.details = hit._source
                                } else {
                                    infos.images.push(hit._source.image_url)
                                    hit._source.results.map((res, index) => {
                                        //concat += res+"|";
                                        //console.log(res)
                                        if (!infos.referentiels.includes(res)) {
                                            infos.referentiels.push(res);
                                        }
                                    })
                                }

                            }
                        })

                        setLoading(true)

                        let tab = []
                        //Start Ordring images
                        //Les premiers positions pour les _ph.
                        infos.images.map((image, index) => {
                            if (image.includes("_ph.")) {
                                tab.push(image)
                                infos.images.splice(index, 1)
                            }
                        })

                        //La deuxieme priorités pour les -label
                        infos.images.map((image, index) => {
                            if (image.includes("-label")) {
                                tab.push(image)
                                infos.images.splice(index, 1)
                            }
                        })

                        //Ajouter les autres images qui restent au tableau
                        infos.images.map((image, index) => {
                            tab.push(image)
                            infos.images.splice(index, 1)
                        })
                        //End ordering images
                        infos.images = tab




                        setProductInfos(infos)

                        //setImages(images)
                    })
                })
        }, [])

        //product_infos.images = changeImagesOrder(product_infos.images)

        console.log(product_infos)
        // setProductInfos(infos)
        return product_infos
    }


    function changeImagesOrder(images) {
        let tab = []
        //Les premiers positions pour les _ph.
        images.map((image, index) => {
            if (image.includes("_ph.")) {
                tab.push(image)

                images.splice(index, 1)
            }
        })

        //La deuxieme priorités pour les -label
        images.map((image, index) => {
            if (image.includes("-label")) {
                tab.push(image)
                images.splice(index, 1)
            }
        })

        //Ajouter les autres images qui restent au tableau
        images.map((image, index) => {
            tab.push(image)
            images.splice(index, 1)
        })

        return tab
    }


    return (

        <div className="marques-main-box">
            <div className="marques-pres-box">
                <div className="pres-elements-box">
                    <p className="text-pres">
                        Vous devez remplacer un produit de marque {ReactHtmlParser(marque.name)} dont vous possèdez
                        l'étiquette. Afin de determiner avec exactitude celui-ci, veuillez nous communiquer en priorité
                        le Code usine.
                    </p>
                </div>
                <div className="pres-elements-box">
                    <img className="img-pres"
                         src={marque_logo_url}/>
                </div>
                <div id="btnListe" className="pres-elements-box">
                    <button className="btn-pres">Liste non exhaustive de tous les produits</button>
                </div>
            </div>
            <div className="container main-content ">
                <label className="text-pres">.../{marque.name}/{famille_princ.name}</label>
            </div>
            <div className="container main-content">
                <Table variant='simple'>
                    {

                        makeDecisionToShowProducts(products, props).map((product, index) => {
                            {

                                obj = GetProductInfosFromGraphQL(product.features.produitRefSetem)
                                //console.log(obj)
                                //obj.images = changeImagesOrder(obj.images)
                            }
                            return (

                                <Tbody className="tbody">
                                    <Tr>
                                        <Td rowspan="3"><Center><img
                                            src={(obj.images) ? (obj.images.length > 0 ? obj.images[0] : "") : ""}
                                            height="150px" width="150px"/></Center></Td>
                                        <Td colspan="7" className="text-pres">
                                            <Link to={`/produit/${product.id}`} >{(obj.details) ? obj.details.code+" "+obj.details.usine : "NAN"}
                                            </Link>
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td colspan="7"> <Tag variant='solid' colorScheme='blue'>Réference
                                            Usine</Tag> {(obj.details) ? obj.details.usine : "NAN"}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td colspan="2"><Tag variant='solid' colorScheme='blue'>Réference
                                            SETEM</Tag> {(obj.details) ? obj.details.reference : "NAN"}
                                        </Td>
                                        <Td colspan="3"><Tag variant='solid'
                                                             colorScheme='blue'>Code</Tag> {(obj.details) ? obj.details.code : "NAN"}
                                        </Td>
                                        <Td colspan="2"><Tag variant='solid'
                                                             colorScheme='blue'>Marque</Tag> {(obj.details) ? obj.details.brand : "NAN"}
                                        </Td>
                                    </Tr>
                                </Tbody>

                            )
                        })
                    }
                </Table>


            </div>

           {/* <div className="containPictoBox">
                <MediaPicto/>
            </div>*/}

        </div>
    )
}

export default ProduitsFamille;