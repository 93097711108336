import React from "react"
import { Footer } from "../../components/footer/Footer"
import { Header } from "../../components/header/Header"
import BannMarque from "../../components/marque/BanniereMarque"
import ProduitsFamille from "../../components/produits/Produits"

const MarqueProductPage = ({ location }) => {
  console.log("location", location)
  return (
    <div>
      <Header />
      <BannMarque
        id={
          typeof location.state === `undefined`
            ? "0"
            : location.state.params.id_marque
        }
      />
      <ProduitsFamille
        id_marque={
          typeof location.state === `undefined`
            ? "0"
            : location.state.params.id_marque
        }
        id_famille={
          typeof location.state === `undefined`
            ? "0"
            : location.state.params.id_famille
        }
      />
      <Footer />
    </div>
  )
}

export default MarqueProductPage
